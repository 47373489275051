import { observable } from "mobx";
import { MicroApp } from "../../utils/micro/qiankun";
import { mergeObject } from "../../utils";
import { getDefaultTenantInfo, getDefaultUserInfo, TenantInfo, UserInfo } from "./types";

const genUserStore = () => {
  return observable({
    info: getDefaultUserInfo(),
    tenantInfo: getDefaultTenantInfo(),
    onUpdateInfo(info: Partial<UserInfo>) {
      this.info = mergeObject<UserInfo>(this.info, info);
    },
    onUpdateTenantInfo(tenatInfo: Partial<TenantInfo>) {
      this.tenantInfo = mergeObject<TenantInfo>(this.tenantInfo, tenatInfo);
    },
    onLogOut() {
      this.info = getDefaultUserInfo();
      this.tenantInfo = getDefaultTenantInfo();
      MicroApp.logout();
    }
  });
};
const userStore = genUserStore();
export const useUserStore = () => {
  return userStore;
};
