import React, { Component } from "react";
import { Checkbox, message, Modal, Radio, Spin } from "antd";
// import { LoadingLG } from "@components/loading/loding";
const CheckboxGroup = Checkbox.Group;
import "./storeList.scss";
import { useAxios } from "../../utils/axios/useAxios";
import dayjs from "dayjs";
const { request } = useAxios();

interface Column {
  field: string;
  label: string;
}
// interface Props {
//   columnsKey: () => Column[];
//   searchObj: Recordable;
//   pageSize: number;
//   current: number;
//   total: number;
//   exportUrl: string;
//   exportFileName: string;
// }
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  // register
  ref: any;
}
export interface ExportDataModalParams {
  columnsKey: () => Column[];
  searchObj: Recordable;
  pageSize: number;
  current: number;
  total: number;
  exportUrl: string;
  exportFileName: string;
}
interface State {
  isShow: boolean;
  isLoading: boolean;
  columnsKey: Column[];
  selectList: any[];
  isAllChecked: boolean;
  exportType: number;
  params?: ExportDataModalParams;
  loading: boolean;
}

class ExportStoreList extends Component<Props, State> {
  state: State = {
    isShow: false,
    isLoading: false,
    columnsKey: [],
    selectList: [],
    isAllChecked: true,
    exportType: 1,
    loading: false
  };
  // UNSAFE_componentWillMount() {}
  onShow(params: ExportDataModalParams) {
    this.setState(
      {
        params: params
      },
      () => {
        const initColumnsKey: Column[] = [];
        params!.columnsKey().map((item) => {
          if (item.field == "action") return;
          initColumnsKey.push({
            label: item.label,
            field: item.field
          });
        });
        const initSelectList = initColumnsKey.map((item) => item.field);
        this.setState({
          columnsKey: initColumnsKey,
          selectList: initSelectList,
          isShow: true
        });
      }
    );
  }
  // 导出
  exportStoreList = () => {
    const { selectList, columnsKey, loading } = this.state;
    if (loading) {
      return;
    }
    const list: any[] = [];
    selectList.forEach((item) => {
      columnsKey.forEach((it) => {
        if (item == it.field) {
          list.push(it);
        }
      });
    });
    if (list.length == 0) {
      message.error("至少选择一列导出！");
      return;
    }
    const fields: Recordable = {};
    list.forEach((item) => {
      fields[item.field] = item.label;
    });
    this.setState({
      loading: true
    });
    // 关闭导出弹窗
    // this.params.closeExportStoreList();
    request({
      url: this.state.params!.exportUrl,
      params: {
        ...this.state.params!.searchObj,
        size: this.state.exportType == 1 ? this.state.params!.pageSize : this.state.params!.total,
        current: this.state.exportType == 1 ? this.state.params!.current : 1
      },
      fields: encodeURI(JSON.stringify(fields)),
      responseType: "blob",
      sheetName: "Sheet1"
    })
      .then((res: any) => {
        this.setState({ isLoading: false });
        // let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1];
        const blob = new Blob([res]);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const a = document.createElement("a");
          a.download =
            (this.state.params!.exportFileName ?? "导出数据") +
            `_${dayjs().format("YYYY-MM-DD")}` +
            ".xlsx";
          // a.download = fileName
          a.href = URL.createObjectURL(blob);
          document.querySelector("body")!.appendChild(a);
          a.click();
          document.querySelector("body")!.removeChild(a);
        };
      })
      .catch(() => {
        this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };
  // 单个选择
  childrenChange = (list: any[]) => {
    const { columnsKey, isAllChecked } = this.state;
    let _isAllChecked = isAllChecked;
    columnsKey.length === list.length ? (_isAllChecked = true) : (_isAllChecked = false);
    this.setState({
      selectList: list,
      isAllChecked: _isAllChecked
    });
  };
  // 全选/取消全选
  onCheckAllChange = (e: { target: { checked: any } }) => {
    // eslint-disable-next-line prefer-const
    let { columnsKey, selectList } = this.state;
    e.target.checked ? (selectList = columnsKey.map((item) => item.field)) : (selectList = []);
    this.setState({ isAllChecked: e.target.checked, selectList });
  };
  render() {
    const { selectList, columnsKey, isAllChecked } = this.state;
    return (
      <div id="inStorage_modal">
        {this.state.isLoading ? <div></div> : null}
        <Modal
          className="export_inStorage_modal"
          title="数据导出"
          visible={this.state.isShow}
          onOk={this.exportStoreList}
          onCancel={() => {
            this.setState({
              isShow: false
            });
            // this.params.closeExportStoreList();
          }}
          cancelText="取消"
          okText="确定"
        >
          <Spin spinning={this.state.loading}>
            <div className="exportType">
              <Radio.Group
                name="radiogroup"
                value={this.state.exportType}
                onChange={(e) => {
                  this.setState({ exportType: e.target.value });
                }}
              >
                <Radio value={1}>导出当前查询分页数据</Radio>
                <Radio value={2}>导出当前查询全部数据</Radio>
              </Radio.Group>
            </div>
            <div
              className="CheckAll"
              style={{ borderBottom: "1px solid #e9e9e9", marginBottom: 8, paddingBottom: 2 }}
            >
              <Checkbox
                onChange={(e) => {
                  this.onCheckAllChange(e);
                }}
                checked={isAllChecked}
              >
                导出表头选择
              </Checkbox>
            </div>
            <CheckboxGroup
              options={columnsKey.map((i) => ({ label: i.label, value: i.field }))}
              value={selectList}
              onChange={(list) => {
                this.childrenChange(list);
              }}
            />
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default ExportStoreList;
