export function mergeObject<T>(source: Recordable, data: Recordable): T {
  const obj: Recordable = { ...source };
  Object.entries(data).forEach(([key, value]) => {
    obj[key] = value;
  });
  return obj as T;
}
export function sleep(delay = 0) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}
