import React from "react";
// import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "@/routes";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
// import zhCN from "antd/locale/zh_CN";
import zhCN from "antd/lib/locale/zh_CN";
// import "antd/lib/style";
import { ModalWrapper } from "@/layouts/modalWrapper";
dayjs.locale("zh-cn");

function App() {
  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
        <ModalWrapper>
          <RouterProvider router={router} />
        </ModalWrapper>
      </ConfigProvider>
    </div>
  );
}

export default App;
