import { Spin } from "antd";
import React from "react";

export default function Loading(props: any) {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>Loader timed out!</div>;
    } else if (props.pastDelay) {
      return (
        <div className="h-80vh w-full flex justify-center items-center">
          <Spin size={"large"} spinning={true}></Spin>
        </div>
      );
    } else {
      return null;
    }
  } else if (props.error) {
    return <div>Error! Component failed to load</div>;
  } else {
    return null;
  }
}
