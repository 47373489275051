import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "@/pages/App";
import reportWebVitals from "@/utils/reportWebVitals";
import { MicroApp } from "@/utils/micro/qiankun";
import { RenderProps } from "@/utils/micro/types";
import "antd/dist/antd.less";
import "./virtual:windi.css";
import "./public.path";
function render(props?: RenderProps) {
  const el = props?.container
    ? props.container.querySelector("#root")
    : document.querySelector("#root");
  ReactDOM.render(<App />, el);
}
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  // console.log("[react16] react app bootstraped");
}

export async function mount(props: RenderProps) {
  // console.log("[react16] props from main framework", props);
  MicroApp.onUpdateAccept();
  render(props);
}

export async function unmount(props: RenderProps) {
  const el = props?.container
    ? props.container.querySelector("#root")
    : document.querySelector("#root");
  ReactDOM.unmountComponentAtNode(el!);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
