/*
 * @Author: LuanZeHui
 * @Date: 2022-05-16 10:52:40
 * @LastEditors: LuanZeHui
 * @LastEditTime: 2022-06-07 16:51:44
 * @Description: file content
 */
import { useUserStore } from "@/store/user";
import { MicroAppRoute } from "./types";
// 子应用的名称
const microAppName = "lce";
export const MicroApp = {
  // 更新用户信息
  onUpdateAccept: () => {
    if (!isQianKun) return;
    const state = window._qk.getState();
    const userStore = useUserStore();
    userStore.onUpdateInfo(state.userInfo);
    userStore.onUpdateTenantInfo(state.tentantInfo);
  },
  // 更新菜单时调用 用于和主应用通信
  onUpdateMenu: (menu: MicroAppRoute[]) => {
    if (!isQianKun) return;
    window._qk.onUpdateRouters(qkRoutesHandler(menu), microAppName);
  },
  // 用户登出 托管到主应用中
  logout: () => {
    if (isQianKun) {
      window._qk.onLogOut();
      return;
    }
    location.href = "/app/lce/login";
  }
};
// 是否在qiankun环境
export const isQianKun = window.__POWERED_BY_QIANKUN__;

// 把路由追加上后缀 #QK
export const qkRoutesHandler = (routes: MicroAppRoute[]) => {
  if (!isQianKun) return routes;
  const rs: MicroAppRoute[] = routes.map((route) => {
    const path = qkGetPath(route.path);
    return {
      ...route,
      path: path,
      name: name,
      children: route.children ? qkRoutesHandler(route.children) : undefined,
      element: null,
      meta: {
        ...route.meta,
        iconfont: route.icon,
        authorKey: route.code,
        title: route.name,
        path,
        id: route.id,
        parentId: route.parentid,
        microAppName: "lce"
      }
    } as unknown as MicroAppRoute;
  });
  return rs;
};
// 路径转换 /a -> /app/appName/a
export const qkGetPath = (path: string) => {
  if (!isQianKun) return path;
  if (path.startsWith("/app/")) return path;
  return `/app/${microAppName}${path}`;
};
