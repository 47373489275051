import { createBrowserRouter } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "./Loading";

const Layout = Loadable({
  loader: () => import("@/layouts/default"),
  loading: Loading
});

const App = Loadable({
  loader: () => import("@/pages/App"),
  loading: Loading
});

const LowcodeRenderPage = Loadable({
  loader: () => import("@/pages/lowcode/index"),
  loading: Loading
});

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/app",
          element: <App />
        },
        {
          path: "/v/:vid",
          element: <LowcodeRenderPage />
        }
      ]
    }
  ],
  {
    basename: process.env.NODE_ENV === "development" ? "/" : "/app/lce"
  }
);

export default router;
