/*
 * @Author: LuanZeHui
 * @Date: 2022-03-29 08:51:39
 * @LastEditors: LuanZeHui
 * @LastEditTime: 2022-06-06 19:06:22
 * @Description: file content
 */
import { service } from ".";

import { AxiosPromise } from "axios";

import { config } from "./config";

const { default_headers } = config;

export const useAxios = () => {
  const request = <T>(option: AxiosConfig): AxiosPromise<T> => {
    const {
      url,
      method,
      params,
      data,
      headersType,
      fields,
      sheetName,
      responseType,
      onUploadProgress
    } = option;
    // tenantPromise.promise.then(()=>)
    let headers: Recordable = {
      "Content-Type": headersType || default_headers,
      Authorization: "Basic " + "c2luemV0ZWNoLWVycDpzaW56ZXRlY2hfc2VjcmV0"
    };
    if (fields) {
      headers = {
        ...headers,
        fields: fields || "",
        isExport: !!fields,
        sheetName: sheetName || ""
      };
    }
    // for (const k of ["fields", "isExport", "sheetName"]) {
    //   if (option[k]) {
    //     headers[k] = option[k];
    //   }
    // }
    return service({
      url: url,
      method,
      params,
      data,
      responseType: responseType,
      headers,
      onUploadProgress: onUploadProgress
    });
  };

  return {
    request
  };
};
